import React from "react"
import Layout from "../components/Layout"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import SEO from "../components/SEO"
import styled from "styled-components"

const Error = () => {
  return (
    <Layout>
      <SEO title="Error" />
      <Wrapper>
        <h1>Oops! You hit a dead end!</h1>
        <AniLink fade to="/">
          Go Home
        </AniLink>
      </Wrapper>
    </Layout>
  )
}

const Wrapper = styled.div`
  padding: 0 10%;
  width: 100vw;
  text-align: center;
`

export default Error
